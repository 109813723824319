import { FormikProps } from "formik";
import {
  CURRENCIES,
  CartItem,
  CountryInterface,
  ProductItemInterface,
  StoreInterface,
  VariantItem,
} from "../../interfaces";
import { iconColors } from "./icon-colors";
const getStyles = (styles) => {
  return styles as React.CSSProperties;
};

//set the destination scroll left position
function scrollFunction(e, sc, eAmt, start) {
  e.scrollLeft = eAmt * sc + start;
}

//incrementally slide the slider mimicing smoothscroll
function SmoothHorizontalScrolling(e: HTMLElement, time: number, amount: number, start: number) {
  const eAmt = amount / 100;
  let curTime = 0;
  let scrollCounter = 0;
  while (curTime <= time) {
    window.setTimeout(scrollFunction, curTime, e, scrollCounter, eAmt, start);
    curTime += time / 100;
    scrollCounter++;
  }
}

function getFieldvalues(name: string, form: FormikProps<any>, type: "text" | "number" = "text") {
  const fromDotNotation = (place) => {
    if (name.includes(".")) {
      const parts = name.split(".");

      return place[parts[0]] ? place[parts[0]][parts[1]] : undefined;
    }
    return place[name];
  };

  return {
    name,
    value: fromDotNotation(form.values),
    onChange:
      type === "text"
        ? form.handleChange
        : (e: React.ChangeEvent<HTMLInputElement>) =>
            form.setFieldValue(name, Number.isNaN(Number(e.target?.value)) ? 0 : Number(e.target?.value)),
    onBlur: form.handleBlur,
    error: fromDotNotation(form.touched) && fromDotNotation(form.errors) ? fromDotNotation(form.errors) : null,
    "data-has-error": fromDotNotation(form.touched) && fromDotNotation(form.errors) !== undefined,
    errors: fromDotNotation(form.errors),
  };
}

const copyToClipboard = (str: string) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  const selected = (document.getSelection()?.rangeCount ?? 0) > 0 ? document.getSelection()?.getRangeAt(0) : false;
  el.select();
  const successful = document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection()?.removeAllRanges();
    document.getSelection()?.addRange(selected);
  }

  return successful;
};

function getAvatarBg(name: string = "") {
  // const char = name.charAt(0).toLowerCase();
  // const characters = "0123456789abcdefghijklmnopqrstuvwxyz";

  // const index = characters.indexOf(char);

  // return iconColors[index] ?? iconColors[37];

  const char = name.charAt(0).toLowerCase();

  switch (true) {
    case char >= "a" && char <= "c":
      return "bg-accent-yellow-500";
    case char >= "d" && char <= "f":
      return "bg-primary-500";
    case char >= "g" && char <= "i":
      return "bg-accent-green-500";
    case char >= "j" && char <= "l":
      return "bg-accent-orange-500";
    case char >= "m" && char <= "o":
      return "bg-accent-red-500";
    case char >= "p" && char <= "r":
      return "bg-black";
    case char >= "s" && char <= "u":
      return "bg-accent-red-500";
    case char >= "v" && char <= "z":
      return "bg-purple-600";
    default:
      return "bg-gray-600";
  }

  // switch (true) {
  //   case char >= "a" && char <= "e":
  //     return "bg-danger-500";
  //   case char >= "f" && char <= "j":
  //     return "bg-primary-700";
  //   case char >= "k" && char <= "o":
  //     return "bg-success";
  //   case char >= "p" && char <= "u":
  //     return "bg-yellow-700";
  //   case char >= "v" && char <= "z":
  //     return "bg-dark";
  //   default:
  //     return "bg-primary-500";
  // }
}

const arrayToInputValues = (arr: string[]) => arr.map((val) => ({ text: val, value: val }));

const paramsFromObject = (payload: any) => {
  const params = new URLSearchParams();
  Object.keys(payload).map((key) => {
    if (payload[key]) {
      if (typeof payload[key] === "object") {
        Object.keys(payload[key]).map((key2) => {
          params.set(`${key}[${key2}]`, payload[key][key2]);
        });
      } else {
        params.set(key, payload[key]);
      }
    }
  });
  return params;
};

// const toCurrency = (amount, currency?: string, decimals?: number) => {
//   return `${currency || getUserCountry().currency || "NGN"} ${amountFormat(amount, decimals)}`;
// };

// const amountFormat = (amount, toFixed = 2) => {
//   return Number(amount)
//     .toFixed(toFixed)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

export function on<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["addEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.addEventListener) {
    obj.addEventListener(...(args as Parameters<HTMLElement["addEventListener"]>));
  }
}

export function off<T extends Window | Document | HTMLElement | EventTarget>(
  obj: T | null,
  ...args: Parameters<T["removeEventListener"]> | [string, Function | null, ...any]
): void {
  if (obj && obj.removeEventListener) {
    obj.removeEventListener(...(args as Parameters<HTMLElement["removeEventListener"]>));
  }
}

const sluggify = (str: string) => {
  return str
    .replace(/[^\w\s]/gi, "")
    .trim()
    .toLocaleLowerCase()
    .split(" ")
    .join("-");
};

function getRandString(length) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const reloadPage = (e?: React.MouseEvent<HTMLButtonElement>) => {
  window.location.reload();
};

const defaultDateOptions: any = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "2-digit",
  hourCycle: "h12",
};

function formatDate(date: string, options = defaultDateOptions) {
  try {
    const shortEnNGFormatter = new Intl.DateTimeFormat("en-GB", options);
    return shortEnNGFormatter.format(new Date(date));
  } catch (e) {
    return "N/A";
  }
}

function formatDateTime(date: string, options = defaultDateOptions) {
  try {
    const d = formatDate(date, options).split(",");
    const datetime = d[0] + "," + d[1].toUpperCase();
    return datetime;
  } catch (e) {
    return "N/A";
  }
}

const capitalizeFirstLetter = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

const timeToClock = (time: number) => {
  const minutes = Math.floor(time / 60000);
  const seconds = Math.floor((time % 60000) / 1000);

  return `${minutes}mins ${seconds}secs`;
};

function checkOverflow(el) {
  if (!el) {
    return false;
  }

  var curOverflow = el.style.overflow;

  if (!curOverflow || curOverflow === "visible") el.style.overflow = "hidden";

  var isOverflowing = el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight;

  el.style.overflow = curOverflow;

  return isOverflowing;
}

function millify(num: number, fractionDigits: number = 0) {
  if (!num) return 0;

  // if (num % 1 === 0) {
  //   fractionDigits = 0;
  // }

  function getStr() {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(fractionDigits) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(fractionDigits) + "M"; // convert to M for number from > 1 million
    } else if (num < 1000) {
      return num.toFixed(fractionDigits); // if value < 1000, nothing to do
    }
  }

  switch (Math.sign(num)) {
    case -1:
      num = Math.abs(num);
      return "-" + getStr();
    case 1:
      return getStr();
    default:
      return getStr();
  }
}

const removeCountryCode = (phone: string) => {
  const splitPhone = phone.split("-");

  if (splitPhone.length > 1) {
    return `0${splitPhone[1]}`;
  }
  return phone;
};

const genarateStringFromVariantValues = (values: { [key: string]: string }) => {
  let string = "";

  if (!values) {
    return "-";
  }

  Object.values(values).forEach((val, index) => {
    string += index === 0 ? val : ` - ${val}`;
  });

  return string;
};

const hasDuplicates = (array: string[]) => {
  var valuesSoFar = Object.create(null);
  for (var i = 0; i < array.length; ++i) {
    var value = array[i];
    if (value in valuesSoFar) {
      return true;
    }
    valuesSoFar[value] = true;
  }
  return false;
};

const getVariantFromItemsList = (i: CartItem) => {
  return i?.variant_id ? i.object?.variants?.options.find((v) => v.id === i.variant_id) : null;
};

const removeUnderscores = (str: string) => str.replace(/_/g, " ");

const generateHoursInterval = (startHourInMinute, endHourInMinute, interval) => {
  const times = [];

  for (let i = 0; startHourInMinute < 24 * 60; i++) {
    if (startHourInMinute > endHourInMinute) break;

    var hh = Math.floor(startHourInMinute / 60); // getting hours of day in 0-24 format

    var mm = startHourInMinute % 60; // getting minutes of the hour in 0-55 format

    const AMPM = hh >= 12 ? "PM" : "AM";
    const hour = hh % 12 === 0 ? 12 : hh % 12;

    times[i] = ("0" + hour).slice(-2) + ":" + ("0" + mm).slice(-2) + AMPM;

    startHourInMinute = startHourInMinute + interval;
  }

  return times;
};

declare global {
  interface Window {
    COUNTRY: CountryInterface;
    CURRENCY: CURRENCIES;
    CONVERSION_RATES: { [key: string]: number };
  }
}

const getUserCountry = () => {
  if (typeof window !== "undefined" && window.COUNTRY) return window.COUNTRY;
  return {
    name: "Nigeria",
    currency: "NGN",
    code: "NG",
    dial_code: "+234",
    emoji: "🇳🇬",
  };
};

const getProductsCurrency = () => {
  return window?.CURRENCY ?? CURRENCIES.NGN;
};

const toCurrency = (amount, currency?: string, decimals?: number) => {
  return `${currency || getUserCountry().currency || "NGN"} ${amountFormat(amount, decimals)}`;
};

// const amountFormat = (amount, toFixed = 2) => {
//   return Number(amount)
//     .toFixed(toFixed)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

const amountFormat = (amount: number | string, toFixed: number = 2): string => {
  const numericAmount = parseFloat(amount.toString());

  if (isNaN(numericAmount)) {
    // throw new Error("Invalid amount provided");
    return "";
  }

  const fixedAmount = numericAmount.toFixed(toFixed);
  const [integerPart, decimalPart] = fixedAmount.split(".");
  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

// const toCurrency = (amount, currency?: string, convert: boolean = true, decimals?: number) => {
//   const conversionRate = convert && typeof window !== "undefined" ? window?.CONVERSION_RATES[currency] ?? 1 : 1;
//   const cx = (() => {
//     if (currency) return currency;

//     if (typeof window !== "undefined" && window.CURRENCY) return window.CURRENCY;

//     return "NGN";
//   })();

//   return `${cx} ${amountFormat(amount / conversionRate, decimals)}`;
// };

// const amountFormat = (amount, toFixed = 2) => {
//   return Number(amount)
//     .toFixed(toFixed)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
// };

function shuffleArray<T>(array: T[]) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

const delay = async (time: number): Promise<void> => {
  return new Promise((resolve) => {
    const id = setTimeout(() => {
      resolve();
      clearTimeout(id);
    }, time);
  });
};

const phoneObjectFromString = (phone: string) => {
  const phoneSplit = phone?.split("-");

  return {
    code: phoneSplit.length > 1 ? phoneSplit[0] : "+234",
    digits: phoneSplit.length > 1 ? phoneSplit[1] : phoneSplit[0],
  };
};

const phoneObjectToString = (phone: { code: string; digits: string }) => `${phone.code}-${phone.digits}`;

const toAppUrl = (str: string, showProtocol: boolean = true, app: boolean = false) => {
  let url = `${app ? process.env.NEXT_PUBLIC_DASHBOARD_URL : process.env.NEXT_PUBLIC_APP_URL}/${str}`;

  if (!showProtocol) {
    url = url.split("//")[1];
  } else if (app && typeof localStorage !== "undefined") {
    const souceAd = JSON.parse(localStorage?.getItem("sourceAd") ?? "{}"); //input ad params
    url = url + `?${paramsFromObject(souceAd)}`;
  }

  return url;
};

const subdomainStoreLink = (slug: string, withProtocol: boolean = false, searchParams?: string) => {
  return `${withProtocol ? "https://" : ""}${slug}.${process.env.NEXT_PUBLIC_APP_URL.replace(/^https?:\/\//, "")}${
    searchParams ? searchParams : ""
  }`;
};

function paramsToArray(params: any) {
  const array: any[] = [];
  for (let param in params) {
    if (typeof params[param] === "object") {
      array.push(JSON.stringify(params[param]));
    } else {
      array.push(params[param]);
    }
  }
  return array;
}

const parseJwtTokens = (token: string) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const toNaira = (amount: number) => amount / 100;

const toBase64 = (string) => Buffer.from(string).toString("base64");
const fromBase64 = (string) => Buffer.from(string, "base64").toString();

function hexToRgb(hex) {
  hex = hex.replace(/^#/, "");
  const bigint = parseInt(hex, 16);
  return { r: (bigint >> 16) & 255, g: (bigint >> 8) & 255, b: bigint & 255 };
}

function generateComplementaryHexColor(inputHex) {
  // Remove the '#' character if it's at the beginning of the input hex code
  inputHex = inputHex.replace(/^#/, "");

  // Parse the input hex into its red, green, and blue components
  const r = parseInt(inputHex.slice(0, 2), 16);
  const g = parseInt(inputHex.slice(2, 4), 16);
  const b = parseInt(inputHex.slice(4, 6), 16);

  // Calculate the complementary color by subtracting each component from 255 (FF in hexadecimal)
  const complementaryR = 255 - r;
  const complementaryG = 255 - g;
  const complementaryB = 255 - b;

  // Convert the complementary components back to hexadecimal format
  const complementaryHex = `#${complementaryR.toString(16).padStart(2, "0")}${complementaryG
    .toString(16)
    .padStart(2, "0")}${complementaryB.toString(16).padStart(2, "0")}`;

  return complementaryHex;
}

function generateComplementaryHexColor2(inputHex: string): string {
  // Remove the '#' character if it's at the beginning of the input hex code
  inputHex = inputHex.replace(/^#/, "");

  // Parse the input hex into its red, green, and blue components
  const r: number = parseInt(inputHex.slice(0, 2), 16);
  const g: number = parseInt(inputHex.slice(2, 4), 16);
  const b: number = parseInt(inputHex.slice(4, 6), 16);

  // Calculate the complementary color by adjusting the hue by 180 degrees (inverting)
  const hslOriginal: number[] = rgbToHsl(r, g, b);
  const complementaryHue: number = (hslOriginal[0] + 0.5) % 1; // Inverted hue

  // Slightly reduce the lightness for contrast
  const complementaryLightness: number = hslOriginal[2] - 0.1; // Adjust this value as needed

  // Ensure the lightness is within the valid range [0, 1]
  const clampedComplementaryLightness: number = Math.max(0, Math.min(1, complementaryLightness));

  // Convert the complementary HSL color back to RGB
  const [complementaryR, complementaryG, complementaryB]: number[] = hslToRgb(
    complementaryHue,
    hslOriginal[1],
    clampedComplementaryLightness
  );

  // Convert the complementary components back to hexadecimal format
  const complementaryHex: string = `#${Math.round(complementaryR).toString(16).padStart(2, "0")}${Math.round(
    complementaryG
  )
    .toString(16)
    .padStart(2, "0")}${Math.round(complementaryB).toString(16).padStart(2, "0")}`;

  return complementaryHex;
}

function generateComplementaryHexColor3(hexColor) {
  // Convert hex to RGB
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the complementary color
  const compR = 255 - r;
  const compG = 255 - g;
  const compB = 255 - b;

  // Check if the complementary color is dark enough (adjust threshold as needed)
  const isComplementaryDarkEnough = (compR + compG + compB) / 3 < 128;

  // If not dark enough, adjust the complementary color to make it darker
  const darkeningFactor = 0.6; // You can adjust this factor to control the darkness
  const darkComplementaryColor = isComplementaryDarkEnough
    ? `#${compR.toString(16).padStart(2, "0")}${compG.toString(16).padStart(2, "0")}${compB
        .toString(16)
        .padStart(2, "0")}`
    : `#${Math.round(compR * darkeningFactor)
        .toString(16)
        .padStart(2, "0")}${Math.round(compG * darkeningFactor)
        .toString(16)
        .padStart(2, "0")}${Math.round(compB * darkeningFactor)
        .toString(16)
        .padStart(2, "0")}`;

  return darkComplementaryColor;
}

function isValidHexCode(str) {
  // Regular expression for a valid hex code
  const hexCodeRegex = /^#?([0-9A-Fa-f]{3}){1,2}$/;

  // Test the string against the regular expression
  return hexCodeRegex.test(str);
}

// function generateComplementaryHexColor2(inputHex: string): string {
//   // Remove the '#' character if it's at the beginning of the input hex code
//   inputHex = inputHex.replace(/^#/, "");

//   // Parse the input hex into its red, green, and blue components
//   const r: number = parseInt(inputHex.slice(0, 2), 16);
//   const g: number = parseInt(inputHex.slice(2, 4), 16);
//   const b: number = parseInt(inputHex.slice(4, 6), 16);

//   // Calculate the complementary color by adjusting the hue by 180 degrees
//   const complementaryR: number = 255 - r;
//   const complementaryG: number = 255 - g;
//   const complementaryB: number = 255 - b;

//   // Convert the complementary components back to hexadecimal format
//   const complementaryHex: string = `#${complementaryR.toString(16).padStart(2, "0")}${complementaryG
//     .toString(16)
//     .padStart(2, "0")}${complementaryB.toString(16).padStart(2, "0")}`;

//   return complementaryHex;
// }

// Function to convert RGB to HSL
function rgbToHsl(r, g, b) {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
}

// Function to convert HSL to RGB
function hslToRgb(h, s, l) {
  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function removeUndefinedValues(obj) {
  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined && obj[key] !== null) {
      result[key] = obj[key];
    }
  }
  return result;
}

function computeMissingRates(rates: { [key: string]: { [key: string]: number } }, baseCurrency: CURRENCIES) {
  if (!rates[baseCurrency]) {
    throw new Error(`Base currency ${baseCurrency} does not exist in the rates object.`);
  }

  const usdRate = rates[baseCurrency]["USD"];
  if (!usdRate) {
    throw new Error(`No USD rate available for base currency ${baseCurrency}.`);
  }

  for (const targetCurrency in rates) {
    if (targetCurrency === baseCurrency) continue;
    if (rates[baseCurrency][targetCurrency]) continue;

    const targetRateFromUSD = rates["USD"][targetCurrency];
    if (targetRateFromUSD) {
      rates[baseCurrency][targetCurrency] = usdRate * targetRateFromUSD;
    }
  }

  return rates[baseCurrency];
}

function getExchangeRates(
  allRates: { [key: string]: { [key: string]: number } },
  defaultCurrency: CURRENCIES,
  allCurrencies: CURRENCIES[]
) {
  try {
    if (allCurrencies.length > 1 || (allCurrencies.length === 1 && allCurrencies[0] !== defaultCurrency)) {
      const rates = computeMissingRates(allRates, defaultCurrency);

      return rates;
    } else {
      return { [defaultCurrency]: 1 };
    }
  } catch (error) {
    console.log("Error setting conversion rates", error);

    return null;
  }
}

export {
  getStyles,
  SmoothHorizontalScrolling,
  paramsToArray,
  getFieldvalues,
  copyToClipboard,
  getAvatarBg,
  arrayToInputValues,
  paramsFromObject,
  toCurrency,
  sluggify,
  getRandString,
  toAppUrl,
  formatDateTime,
  formatDate,
  capitalizeFirstLetter,
  timeToClock,
  checkOverflow,
  millify,
  removeCountryCode,
  genarateStringFromVariantValues,
  hasDuplicates,
  getVariantFromItemsList,
  removeUnderscores,
  generateHoursInterval,
  getUserCountry,
  delay,
  shuffleArray,
  phoneObjectFromString,
  phoneObjectToString,
  amountFormat,
  parseJwtTokens,
  toNaira,
  toBase64,
  fromBase64,
  hexToRgb,
  generateComplementaryHexColor,
  generateComplementaryHexColor2,
  generateComplementaryHexColor3,
  subdomainStoreLink,
  isValidHexCode,
  removeUndefinedValues,
  computeMissingRates,
  getExchangeRates,
};
